import React from "react";
import { useCollapse } from "react-collapsed";

export function Collapse(props) {
  const {
    isExpanded,
    onClick,
    children,
    title,
    className = "",
    required = false,
    error,
  } = props;
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isExpanded,
  });

  return (
    <div className={`payment-item ${className}`}>
      <h4
        className={`title tel-title ${
          isExpanded ? "payment-item-expanded" : ""
        }`}
        {...getToggleProps()}
        onClick={onClick}
      >
        {required && <span className="collapse-required text-required">*</span>}
        {title}
        {error && required && (
          <span className="tooltip-error">{error.message}</span>
        )}
        {children && (
          <i
            style={{
              transform: !isExpanded ? "rotate(90deg)" : "rotate(0deg)",
              transition: ".3s transform ease-in-out",
            }}
            className="fa fa-chevron-down"
          ></i>
        )}
      </h4>
      <div
        className="wrap-tel wrap-main-payment action"
        {...getCollapseProps()}
      >
        {children}
      </div>
    </div>
  );
}
