import React from "react";
import CustomModal from "./CustomModal";
import "./PaymentErrorModal.css";

const PaymentErrorModal = (props) => {
  const { isShowing, toggle } = props;
  const content = (
    <div className="paymentErrorModal">
      <img src="icon-fail.png" alt="icon fail" />
      <p style={{marginTop: "10px"}}>Your payment was failed.</p>
      <p>Please check the card information again.</p>
      <button onClick={toggle}>Ok</button>
    </div>
  );
  const header = "Payment";
  return (
    <CustomModal
      isShowing={isShowing}
      toggle={toggle}
      header={header}
      content={content}
    />
  );
};

export default PaymentErrorModal;
