import { PaymentStepsBreadcrumbItems } from "../enums/enum";

const PaymentStepsBreadcrumb = (props) => {
  const { activeField } = props;

  return (
    <div className="list-title">
      <p
        className={`title-item ${
          activeField === PaymentStepsBreadcrumbItems.GetAQuote ? "active" : ""
        }`}
      >
        Get a quote
      </p>
      <i className="fa fa-angle-right"></i>
      <p
        className={`title-item ${
          activeField === PaymentStepsBreadcrumbItems.ApplyAQuote
            ? "active"
            : ""
        }`}
      >
        Apply
      </p>
      <i className="fa fa-angle-right"></i>
      <p
        className={`title-item ${
          activeField === PaymentStepsBreadcrumbItems.PaymentAQuote
            ? "active"
            : ""
        }`}
      >
        Payment
      </p>
    </div>
  );
};

export default PaymentStepsBreadcrumb;
