import { useEffect, useState } from "react";

const useModal = (props) => {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    if (typeof props?.isShowing !== "undefined") setIsShowing(props?.isShowing);
  }, [props?.isShowing]);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggle,
  };
};

export default useModal;
