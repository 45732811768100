import moment from "moment-timezone";
import {
  API_FORMAT_DATE,
  DEFAULT_FORMAT_DATE,
  PAGE_URL,
} from "../constants/constant";
import { Title, Gender } from "../enums/enum";

export function parseDateToString(date) {
  return moment(new Date(date)).format(API_FORMAT_DATE);
}

export function parseDateToStringDisplay(date) {
  return moment(new Date(date)).format(DEFAULT_FORMAT_DATE);
}

export function getYears() {
  const currentYear = new Date().getFullYear();
  return new Array(currentYear - 1900 + 1)
    .fill()
    .map((_, index) => 1900 + index);
}

export function getDays(month, year) {
  const daysInMonth = new Date(year, month, 0).getDate();
  return new Array(daysInMonth).fill().map((_, index) => 1 + index);
}

export function createPerson(relationship) {
  return {
    title: Title.Mr,
    firstName: "",
    lastName: "",
    gender: Gender.Male,
    birthday: {
      day: 1,
      month: 1,
      year: 2000,
    },
    passport: "",
    relationship: relationship,
    nationality: "",
  };
}

export function getFileExtension(fileName) {
  return fileName.split(".").pop();
}

export function getObjectToQueryParams(options) {
  return options
    ? Object.keys(options)
        .filter((o) => options[o] !== undefined && options[o] !== null)
        .map((o) => `${o}=${encodeURIComponent(options[o])}`)
        .join("&")
    : "";
}

export function getAge(birthDate) {
  var today = new Date();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function convertTitleToGender(title) {
  switch (title) {
    case Title.Mr:
      return Gender.Male;
    case Title.Mrs:
      return Gender.Female;
    case Title.Ms:
      return Gender.Female;
  }
}

export function navigateToStudentInsurance(navigate, agent, agentname) {
  if (PAGE_URL.OSHC.includes("localhost")) {
    const path = PAGE_URL.STUDENT_INSURANCE_AU;
    const options = {
      agentcode: agent || undefined,
      agentname: agentname || undefined,
    };
    const params = getObjectToQueryParams(options);
    const pathWithParams = options ? `${path}?${params}` : path;
    navigate(pathWithParams);
  } else {
    window.location.href = `${PAGE_URL.OSHC}/student-insurance-au/`;
  }
}

export function navigateToGetAQuote(navigate, agent, agentname, quote = "") {
  if (PAGE_URL.OSHC.includes("localhost")) {
    const path = PAGE_URL.OSHC_AU_GET_A_QUOTE;
    const options = {
      agentcode: agent || undefined,
      agentname: agentname || undefined,
      cover: quote,
    };
    const params = getObjectToQueryParams(options);
    const pathWithParams = options ? `${path}?${params}` : path;
    navigate(pathWithParams);
  } else {
    window.location.href = `${PAGE_URL.OSHC}/oshc-au-get-a-quote/?action=get-a-quote`;
  }
}
