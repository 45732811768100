import { useCallback, useMemo, useState } from "react";
import ApiService from "../services/api/apiService";
import { useSearchParams } from "react-router-dom";

const useApiServices = () => {
  const [searchParams] = useSearchParams();
  const agent = useMemo(
    () => new URLSearchParams(searchParams).get("agentcode"),
    [searchParams]
  );

  const getService = useCallback(
    (url, options) => {
      const allOptions = {
        ...options,
        agent,
      };

      return ApiService.get(url, allOptions);
    },
    [agent]
  );

  const postService = useCallback(
    (url, payload, options) => {
      const allPayloads = {
        ...payload,
        agent,
      };
      return ApiService.post(url, allPayloads, options);
    },
    [agent]
  );

  const putService = useCallback(
    (url, payload, options) => {
      const allPayloads = {
        ...payload,
        agent,
      };

      return ApiService.put(url, allPayloads, options);
    },
    [agent]
  );

  const deleteService = useCallback(
    (url, payload, options) => {
      const allPayloads = {
        ...payload,
        agent,
      };
      return ApiService.delete(url, allPayloads, options);
    },
    [agent]
  );

  return {
    getService,
    postService,
    putService,
    deleteService,
  };
};

export default useApiServices;
