import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AppExtendRoutes from "./AppExtendRoutes";
import Layout from "./components/Layout";
import { TIME_ZONE_SYDNEY_AUSTRALIA } from "./constants/constant";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
moment.tz.setDefault(TIME_ZONE_SYDNEY_AUSTRALIA);

export default class App extends Component {
  static displayName = App.name;
  render() {
    return (
      <React.Fragment>
        <Routes>
          <Route path="/" element={<Layout />}>
            {AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })}
          </Route>
          {AppExtendRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
