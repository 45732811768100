import { createSlice } from "@reduxjs/toolkit";

const selectOption = {
  name: "Please select",
  code: "",
};

const initialState = {
  loading: false,
  error: null,
  nationality: [],
  institution: [],
  paymentCountry: [],
  isShowingBanner: false,
  agent: "",
};

const getCommonSlice = createSlice({
  name: "getCommon",
  initialState,
  reducers: {
    fetchCommonRequest(state, action) {
      state.loading = true;
      state.nationality = [];
      state.institution = [];
      state.paymentCountry = [];
      state.agent = action.payload.agent;
    },
    fetchCommonSuccess(state, action) {
      state.loading = false;
      const paymentCountry = action.payload.nationality;
      if (paymentCountry) {
        paymentCountry.unshift(selectOption);
      }
      const institution = action.payload.institution;
      if (institution) {
        institution.unshift(selectOption);
      }
      const nationality = paymentCountry.filter(x => x.code !== "AUS");
      state.nationality = nationality;
      state.paymentCountry = paymentCountry;
      state.institution = institution;
    },
    fetchCommonFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchBannerCommon(state, action) {
      state.isShowingBanner = true;
    },
  },
});

export const {
  fetchCommonRequest,
  fetchCommonSuccess,
  fetchCommonFailure,
  fetchBannerCommon,
} = getCommonSlice.actions;

export default getCommonSlice.reducer;
