import StudentInsurance from "./components/pages/StudentInsurance";
import GetAQuote from "./components/pages/GetAQuote";
import ApplyQuote from "./components/pages/ApplyQuote";
import PaymentAQuote from "./components/pages/PaymentQuote";
import PaypalReturn from "./components/pages/PaypalReturn";
import { PAGE_URL } from "./constants/constant";

const AppRoutes = [
  {
    path: PAGE_URL.STUDENT_INSURANCE_AU,
    element: <StudentInsurance />,
  },
  {
    path: PAGE_URL.OSHC_AU_GET_A_QUOTE,
    element: <GetAQuote />,
  },
  {
    path: PAGE_URL.OSHC_AU_APPLY_A_QUOTE,
    element: <ApplyQuote />,
  },
  {
    path: PAGE_URL.OSHC_AU_PAYMENT_A_QUOTE,
    element: <PaymentAQuote />,
  },
  {
    path: PAGE_URL.PAYPAL_RETURN,
    element: <PaypalReturn />,
  },
  {
    path: PAGE_URL.PAYPAL_CANCEL,
    element: <PaypalReturn status='cancel' />,
  },
  {
    path: PAGE_URL.BACK_TO_TRANSACTION,
    element: <PaymentAQuote />,
  }
];

export default AppRoutes;
