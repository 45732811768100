import React, { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useGetNIBCheckout from "../../hooks/useGetNIBCheckout";
import useNationalities from "../../hooks/useNationalities";
import { Collapse } from "../Collapse";
import {
  GENDER_LIST,
  TITLE_LIST,
  DEFAULT_FORMAT_DATE,
  API_URL,
} from "../../constants/constant";
import moment from "moment";
import "./NIBPayment.css";
import dropin from "braintree-web-drop-in";
import useModal from "../../hooks/useModal";
import PaymentSuccessModal from "../PaymentSuccessModal";
import PaymentErrorModal from "../PaymentErrorModal";
import Spinner from "../Spinner";
import useApiServices from "../../hooks/useApiServices";

const NIBPayment = () => {
  const { postService } = useApiServices();
  const { id: invoiceNumber } = useParams();
  const [expanedIndex, setExpandedIndex] = useState(-1);
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);

  const handleExpand = (index) => {
    setExpandedIndex((prev) => (prev === index ? -1 : index));
  };
  const { data: payment, isLoading: isLoadingPayment } = useGetNIBCheckout({
    invoiceNumber,
  });
  const { data: nationalities } = useNationalities();

  const nationality = useMemo(
    () =>
      payment && nationalities
        ? nationalities?.find(
            (n) => n.code === payment?.personalDetail.nationality
          )?.name
        : "",
    [nationalities, payment]
  );

  const { isShowing, toggle } = useModal();
  const { isShowing: isErrorShowingModal, toggle: toggleErrorShowing } =
    useModal();

  useEffect(() => {
    const initializeBraintree = () =>
      dropin.create(
        {
          // insert your tokenization key or client token here
          authorization: payment?.token,
          container: "#braintree-drop-in-div",
          card: {
            cardholderName: true,
          },
        },
        function (error, instance) {
          if (error) console.error(error);
          else setBraintreeInstance(instance);
        }
      );

    if (payment != null) {
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
  }, [payment]);

  const nibVisaMastercardCheckout = () => {
    if (braintreeInstance) {
      braintreeInstance.requestPaymentMethod((error, payload) => {
        if (error) {
          toggleErrorShowing();
        } else {
          postService(API_URL.TRANS_NIB_VISA_MASTERCARD_CHECKOUT, {
            nonce: payload.nonce,
            invoiceNumber: invoiceNumber,
          })
            .then(() => {
              toggle();
            })
            .catch(() => {
              toggleErrorShowing();
            });
        }
      });
    }
  };

  return (
    <div className="payment-url-container">
      <img
        className="logo"
        alt="Logo"
        src="https://oshcstudents.com.au/wp-content/themes/template_ecvn_v1.1/images/logo-small.png"
      ></img>
      <div className="nib-payment">
        <h3 className="header">NIB Payment</h3>
        <Collapse
          title="Personal Detail"
          isExpanded={expanedIndex === 0}
          onClick={() => handleExpand(0)}
        >
          <div className="personal-information">
            <div className="description">
              {payment?.personalDetail.title !== undefined && (
                <div className="item">
                  <span className="title">Title: </span>
                  <span className="value">
                    {
                      TITLE_LIST.find(
                        (t) => t.value === payment?.personalDetail.title
                      ).label
                    }
                  </span>
                </div>
              )}
              {payment?.personalDetail.firstName &&
                payment?.personalDetail.lastName && (
                  <div className="item">
                    <span className="title">Full Name: </span>
                    <span className="value">{`${payment?.personalDetail.firstName} ${payment?.personalDetail.lastName}`}</span>
                  </div>
                )}
              {payment?.personalDetail.gender !== undefined && (
                <div className="item">
                  <span className="title">Gender:</span>
                  <span className="value">
                    {
                      GENDER_LIST.find(
                        (t) => t.value === payment?.personalDetail.gender
                      ).label
                    }
                  </span>
                </div>
              )}
              {payment?.personalDetail.birthday && (
                <div className="item">
                  <span className="title">Birthday:</span>
                  <span className="value">
                    {moment(new Date(payment?.personalDetail.birthday)).format(
                      DEFAULT_FORMAT_DATE
                    )}
                  </span>
                </div>
              )}
              {payment?.personalDetail.passport && (
                <div className="item">
                  <span className="title">Passport:</span>
                  <span className="value">
                    {payment?.personalDetail.passport}
                  </span>
                </div>
              )}
              {payment?.personalDetail.nationality && (
                <div className="item">
                  <span className="title">Nationality:</span>
                  <span className="value">{nationality}</span>
                </div>
              )}
              {payment?.personalDetail.phone && (
                <div className="item">
                  <span className="title">Phone:</span>
                  <span className="value">{payment?.personalDetail.phone}</span>
                </div>
              )}
              {payment?.personalDetail.email && (
                <div className="item">
                  <span className="title">Email:</span>
                  <span className="value">{payment?.personalDetail.email}</span>
                </div>
              )}
            </div>
          </div>
        </Collapse>
        <Collapse
          title="Payment"
          isExpanded={expanedIndex === 1}
          onClick={() => handleExpand(1)}
        >
          <div className="dropin-wrapper">
            <div className="nib-dropin-form" id="braintree-drop-in-div"></div>
            <input
              type="button"
              className="nib-dropin-btn nib-drop-custom-btn"
              id="nib-checkout-btn"
              name="checkout"
              value="Checkout"
              style={{ marginTop: "10px" }}
              onClick={nibVisaMastercardCheckout}
            />
          </div>
        </Collapse>
        <PaymentSuccessModal isShowing={isShowing} toggle={toggle} />
        <PaymentErrorModal
          isShowing={isErrorShowingModal}
          toggle={toggleErrorShowing}
        />
        <Spinner
          text="Please wait, we are processing your invoice."
          isShowing={isLoadingPayment}
        />
      </div>
    </div>
  );
};

export default NIBPayment;
