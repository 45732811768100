import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import GetAQuoteReducer from "./reducer-get-quote";
import ApplyQuoteReducer from "./reducer-apply-quote";
import GetCommonReducer from "./reducer-common";

const rootReducer = combineReducers({
  routing: routerReducer,
  getAQuoteReducer: GetAQuoteReducer,
  applyQuoteReducer: ApplyQuoteReducer,
  getCommonReducer: GetCommonReducer
});

export default rootReducer;
