import { useCallback, useEffect, useState } from "react";
import { API_URL } from "../constants/constant";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import useApiServices from "./useApiServices";

const useConfig = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getService } = useApiServices();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    getService(API_URL.COMMON_CONFIG)
      .then((data) => {
        setIsLoading(false);
        const cleanHTML = DOMPurify.sanitize(data.bannerHtml, {
          USE_PROFILES: { html: true },
        });

        const bannerHtml = cleanHTML !== "" ? parse(cleanHTML) : "";
        setData({
          ...data,
          bannerHtml: bannerHtml,
        });
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [getService]);

  useEffect(() => {
    fetchData();
  }, []);

  return {
    data,
    isLoading,
    error,
  };
};

export default useConfig;
