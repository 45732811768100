import React from "react";
import CustomModal from "./CustomModal";
import { Button } from "reactstrap";

const ErrorModal = (props) => {
  const { isShowing, toggle, errorData } = props;
  const content = (
    <div className="errorModal">
      <img src="icon-fail.png" alt="icon fail" />
      <div className="content">{errorData}</div>
      <Button className="actionBtn" color="primary" onClick={toggle}>
        Ok
      </Button>
    </div>
  );
  const header = "Error";
  return (
    <CustomModal
      isShowing={isShowing}
      toggle={toggle}
      header={header}
      content={content}
    />
  );
};

export default ErrorModal;
