import { GET, POST, PUT, DELETE } from "./api";
import { API_URL } from "../../constants/constant";

class ApiService {
  static get(requestURL, options) {
    return GET(`${API_URL.BASE}${requestURL}`, options);
  }
  static post(requestURL, payload, options) {
    return POST(`${API_URL.BASE}${requestURL}`, payload, options);
  }
  static put(requestURL, payload, options) {
    return PUT(`${API_URL.BASE}${requestURL}`, payload, options);
  }
  static delete(requestURL, payload, options) {
    return DELETE(`${API_URL.BASE}${requestURL}`, payload, options);
  }
}

export default ApiService;
