import React from "react";
import CustomModal from "./CustomModal";
import "./PaymentSuccessModal.css";

const PaymentSuccessModal = (props) => {
  const { isShowing, toggle } = props;
  const content = (
    <div className="paymentSuccessModal">
      <img src="icon-success.png" alt="icon success" />
      <p>Your payment was successful.</p>
      <button onClick={toggle}>Ok</button>
    </div>
  );
  const header = "Payment";
  return (
    <CustomModal
      isShowing={isShowing}
      toggle={toggle}
      header={header}
      content={content}
    />
  );
};

export default PaymentSuccessModal;
