import React, { useMemo } from "react";
import useModal from "../../hooks/useModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { navigateToStudentInsurance } from "../../utils/utils";
import "../../defaultCss/styles.css";

const MainMenu = () => {
  const navigate = useNavigate();
  const { isShowing: isShowingLogin, toggle: toggleShowingLogin } = useModal();

  const [searchParamsUrl] = useSearchParams();
  const agent = useMemo(
    () => new URLSearchParams(searchParamsUrl).get("agentcode"),
    [searchParamsUrl]
  );
  const agentname = useMemo(
    () => new URLSearchParams(searchParamsUrl).get("agentname"),
    [searchParamsUrl]
  );

  const navigateToStudentInsurancePage = () => {
    navigateToStudentInsurance(navigate, agent, agentname);
  };

  return (
    <div className="menu-main on-desktop">
      <div className="fix-width">
        <div className="group">
          <a href="https://oshcstudents.com.au/" style={{ float: "left" }}>
            <img
              className="lazy"
              src="https://oshcstudents.com.au/wp-content/themes/template_ecvn_v1.1/images/logo-small.png"
              data-src="https://oshcstudents.com.au/wp-content/themes/template_ecvn_v1.1/images/logo-small.png"
              alt="OSHC STUDENTS"
            />
          </a>

          <nav id="primary-menu" className="menu-menu-main-container">
            <ul id="menu-menu-main" className="menu">
              <li
                id="menu-item-60"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-60 current-menu-ancestor"
              >
                <a href="https://oshcstudents.com.au/services/">Services</a>
                <ul className="sub-menu">
                  <li
                    id="menu-item-412"
                    className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current-menu-ancestor current-menu-parent menu-item-has-children menu-item-412 active "
                  >
                    <a href="https://oshcstudents.com.au/services/">
                      Australia
                    </a>
                    <ul className="sub-menu">
                      <li
                        id="menu-item-3749"
                        className="menu-item menu-item-type-post_type menu-item-object-service current-menu-item menu-item-3749 active "
                      >
                        <span onClick={navigateToStudentInsurancePage}>
                          OSHC Student insurance
                        </span>
                      </li>
                      <li
                        id="menu-item-171615"
                        className="menu-item menu-item-type-post_type menu-item-object-service menu-item-171615"
                      >
                        <a href="https://oshcstudents.com.au/visitor-and-work-insurance-australia/">
                          OVHC Visitor insurance
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    id="menu-item-414"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-414"
                  >
                    <a href="https://oshcstudents.com.au/student-insurance-usa/">
                      Other Countries
                    </a>
                    <ul className="sub-menu">
                      <li
                        id="menu-item-503"
                        className="menu-item menu-item-type-post_type menu-item-object-service menu-item-503"
                      >
                        <a href="https://oshcstudents.com.au/student-insurance-usa/">
                          Student insurance
                        </a>
                      </li>
                      <li
                        id="menu-item-722"
                        className="menu-item menu-item-type-post_type menu-item-object-service menu-item-722"
                      >
                        <a href="https://oshcstudents.com.au/visitor-and-work-insurance-usa/">
                          Visitor &amp; work insurance
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-175664"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-175664"
              >
                <a href="https://oshcstudents.com.au/other-services/flywire/">
                  Flywire
                </a>
              </li>
              <li
                id="menu-item-3773"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-3773"
              >
                <a href="https://oshcstudents.com.au/study-tour-australia/">
                  Study Tour
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu-item-6198"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6198"
                  >
                    <a href="https://oshcstudents.com.au/study-tour-australia/">
                      Study Tour Australia
                    </a>
                  </li>
                  <li
                    id="menu-item-6011"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6011"
                  >
                    <a href="https://oshcstudents.com.au/study-tour-united-kingdom/">
                      Study Tour United Kingdom
                    </a>
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-2030"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-2030"
              >
                <a href="https://oshcstudents.com.au/other-services/">
                  Other Services
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu-item-36646"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-36646"
                  >
                    <a href="https://oshcstudents.com.au/pte-platform/">
                      PTE Platform
                    </a>
                  </li>
                  <li
                    id="menu-item-2033"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2033"
                  >
                    <a href="https://oshcstudents.com.au/marketing-solutions/">
                      Marketing solutions
                    </a>
                  </li>
                  <li
                    id="menu-item-3683"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3683"
                  >
                    <a href="https://oshcstudents.com.au/design-branding-website/">
                      Design – Branding &amp; Website
                    </a>
                  </li>
                  <li
                    id="menu-item-13751"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13751"
                  >
                    <a href="https://oshcstudents.com.au/other-services/education-agent-crm/">
                      Education Agent CRM
                    </a>
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-1241"
                className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-1241"
              >
                <a href="https://oshcstudents.com.au/category/special-deals/">
                  Special deals
                </a>
              </li>
              <li
                id="menu-item-614"
                className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-has-children menu-item-614"
              >
                <a href="https://oshcstudents.com.au/category/news/">News</a>
                <ul className="sub-menu">
                  <li
                    id="menu-item-3679"
                    className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-3679"
                  >
                    <a href="https://oshcstudents.com.au/category/news/study-tour-news/">
                      Study Tour News
                    </a>
                  </li>
                  <li
                    id="menu-item-2361"
                    className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-2361"
                  >
                    <a href="https://oshcstudents.com.au/category/news/oshc-ovhc-support/">
                      OSHC &amp; OVHC support
                    </a>
                  </li>
                  <li
                    id="menu-item-12674"
                    className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-12674"
                  >
                    <a href="https://oshcstudents.com.au/category/news/flywire/">
                      Flywire
                    </a>
                  </li>
                  <li
                    id="menu-item-2363"
                    className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-2363"
                  >
                    <a href="https://oshcstudents.com.au/category/news/living-studying-abroad/">
                      Living &amp; Studying abroad
                    </a>
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-63"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-63"
              >
                <a href="https://oshcstudents.com.au/oshc-frequently-asked-questions/">
                  FAQS
                </a>
              </li>
              <li
                id="menu-item-485"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-485"
              >
                <a href="https://oshcstudents.com.au/contact-us/">Contact us</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="menuright">
          {/* <nav id="login-menu" className="menu-menu-login-container">
            <ul id="menu-menu-login" className="menu">
              <li>
                <span onClick={toggleShowingLogin}>Login</span>
                {isShowingLogin && <LoginFormModal isShowing={isShowingLogin} />}
              </li>
            </ul>
          </nav> */}
          <nav id="login-menu" className="menu-menu-login-container">
            <ul id="menu-menu-login1" className="menu">
              {!agentname && (
                <li>
                  <a href="https://oshcstudents.com.au/apply-to-join-us/">
                    <span>Become an Agent</span>
                  </a>
                </li>
              )}

              {agentname && (
                <li>
                  <a
                    href="https://oshcstudents.com.au/member-account/?action=dashboard"
                    className="agent-name-btn"
                  >
                    <span>{agentname.substring(0, 12)}</span>
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
export default MainMenu;
