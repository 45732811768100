// sagas.js
import { call, put, takeEvery } from "redux-saga/effects";
import { API_URL } from "../constants/constant";
import {
  applyRequest,
  applySuccess,
  applyFailure,
  backToTransaction,
  getAgentRequest,
  getAgentSuccess,
} from "../reducers/reducer-apply-quote";
import ApiService from "../services/api/apiService";

export function* applyQuoteSaga() {
  yield takeEvery(applyRequest.type, applySaga);
  yield takeEvery(backToTransaction.type, backToTransactionSaga);
  yield takeEvery(getAgentRequest.type, getAgentSaga);
}

function* getAgentSaga(action) {
  yield put(
    getAgentSuccess({
      agentCode: action.payload.agent,
      agentName: action.payload.agentname,
    })
  );
}

function* applySaga(action) {
  try {
    let apiUrl = API_URL.APPLY;
    const data = yield call(() => ApiService.post(apiUrl, action.payload.data));
    if (data) {
      yield put(applySuccess(data));
      yield call(action.payload.callbackApplySuccess);
    }
  } catch (error) {
    yield put(applyFailure(error.message));
    yield call(action.payload.callbackApplyError);
  }
}

function* backToTransactionSaga(action) {
  try {
    let apiUrl = API_URL.BACK_TO_TRANSACTION;
    const data = yield call(() =>
      ApiService.get(apiUrl, { invoiceNumber: action.payload.data })
    );
    if (data) {
      yield put(applySuccess(data));
      yield call(action.payload.callbackSuccess);
    }
  } catch (error) {
    yield put(applyFailure(error.message));
    yield call(action.payload.callbackError);
  }
}
